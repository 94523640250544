.dropdown {
  position: relative;
  margin: 20px;
  width: fit-content;
}

.menu {
  position: absolute;
  background-color: rgb(248 249 251);
  list-style-type: none;
  margin: 10px 2px;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  width: 250px;
  z-index: 100;
}

.menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.display-btn {
  padding: 2px 4px;
  margin-top: 4px;
  margin-left: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  gap: 6px;
}

.select-btn {
  border-radius: 8px;
  padding: 2px 4px;
}
