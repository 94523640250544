.card.dragging {
    background-color: lightgreen;
  }
  
  .card {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* height: 90px; */
    min-width: 280px;
    font-size: 14px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
  }
  
  .card-id {
    font-size: 12px;
    color: #666;
  }
  
  .card-avatar {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 400;
    padding: 4px;
    padding-top: 5px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background-color: rgb(192 109 37);;
    position: relative;
  }
  
  .card-avatar span {
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    bottom: -2px;
    right: -2px;
    border: #fff 1px solid;
  }
  
  .live {
    background-color: rgb(2 179 69);;
  }
  
  .gone {
    background-color: rgb(223 225 228);;
  }
  
  .card-body {
    display: flex;
    gap: 6px;
    font-size: 14px;
    height: 30px;
    align-items: center;
  }
  
  .card-title {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }
  
  .card-footer {
    display: flex;
    gap: 8px;
    margin-top: 4px;
    height: 20px;
    align-items: center;
  }
  
  .card-footer img {
    border: #f0f0f0 1px solid;
    padding: 2px 4px;
    border-radius: 2px;
  }
  
  .tag-feature {
    /* background-color: #f0f0f0; */
    border: #f0f0f0 1px solid;
    text-align: center;
    color: #666;
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 12px;
  }
  
  .tag-dot {
    height: 8px;
    width: 8px;
    background-color: rgb(190 194 199);
    border-radius: 50%;
    display: inline-block;
  }